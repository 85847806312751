import "./App.css";
import { ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import Download from "./components/Download";
import Footer from "./components/Footer";

function App() {
	return (
		<ParallaxProvider>
			<Helmet>
				<meta property="og:image" content="https://marchespointsverts.be/assets/postpictures/1.jpg" />
			</Helmet>
			<Header />
			<Home />
			<About />
			{/*<Features />*/}
			{/*<Testimonials />*/}
			<Download />
			<Footer />
		</ParallaxProvider>
	);
}

export default App;
