import { ParallaxBanner } from "react-scroll-parallax";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Home = () => {
	return (
		<ParallaxBanner layers={[{ image: "assets/images/hero-bg.jpg", speed: -20 }]} className="aspect-[2/1]" style={{ height: "750px" }}>
			<section id="home" style={{ height: "750px" }}>
				<div className="overlay"></div>
				<div className="home-content">
					<div className="row contents">
						<div className="home-content-left">
							<h3 data-aos="fade-up">Bienvenue</h3>

							<h1 data-aos="fade-up" style={{ marginBottom: 15 }}>
								Marches Points Verts <br />
							</h1>
							<h3 data-aos="fade-up">
								Découvrez les "Points Verts ADEPS"
								<br />
								proches de chez vous.
							</h3>
							<div className="buttons" data-aos="fade-up">
								<AnchorLink href="#download" className="smoothscroll button stroke">
									<span className="icon-circle-down" aria-hidden="true"></span>
									Télécharger
								</AnchorLink>
								{/*<a href="http://player.vimeo.com/video/14592941?title=0&amp;byline=0&amp;portrait=0&amp;color=39b54a" data-lity className="button stroke">
									<span className="icon-play" aria-hidden="true"></span>
									Watch Video
	</a>*/}
							</div>
						</div>

						<div className="home-image-right">
							<img src="assets/images/iphone-app-470.png" alt="" srcSet="assets/images/iphone-app-470.png 1x, assets/images/iphone-app-940.png 2x" data-aos="fade-up" />
						</div>
					</div>
				</div>

				{/* 
				<ul className="home-social-list">
					<li>
						<a href="#">
							<i className="fa fa-facebook-square"></i>
						</a>
					</li>
					<li>
						<a href="#">
							<i className="fa fa-twitter"></i>
						</a>
					</li>
					<li>
						<a href="#">
							<i className="fa fa-instagram"></i>
						</a>
					</li>
					<li>
						<a href="#">
							<i className="fa fa-youtube-play"></i>
						</a>
					</li>
				</ul>
*/}
				<div className="home-scrolldown">
					<a href="#about" className="scroll-icon smoothscroll">
						<span>Scroll Down</span>
						<i className="icon-arrow-right" aria-hidden="true"></i>
					</a>
				</div>
			</section>
		</ParallaxBanner>
	);
};

export default Home;
