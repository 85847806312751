import AnchorLink from "react-anchor-link-smooth-scroll";

const Header = () => {
	return (
		<header id="header" className="row">
			<div className="header-logo">
				<a href="index.html">Marches Points Verts</a>
			</div>

			<nav id="header-nav-wrap">
				<ul className="header-main-nav">
					<li className="current">
						<AnchorLink className="smoothscroll" href="#home" title="accueil">
							Accueil
						</AnchorLink>
					</li>
					<li>
						<AnchorLink className="smoothscroll" href="#about" title="a fonctionnalités">
							Fonctionnalités
						</AnchorLink>
					</li>
					{/*<li>
						<AnchorLink className="smoothscroll" href="#pricing" title="fonctionnalités">
							Fonctionnalités
						</AnchorLink>
					</li>
					<li>
						<AnchorLink className="smoothscroll" href="#testimonials" title="testimonials">
							Testimonials
						</AnchorLink>
	</li>*/}
				</ul>

				<AnchorLink href="#download" title="sign-up" className="button button-primary cta">
					Télécharger
				</AnchorLink>
			</nav>

			<a className="header-menu-toggle" href="#">
				<span>Menu</span>
			</a>
		</header>
	);
};

export default Header;
