const Download = () => {
	return (
		<section id="download">
			<div className="row">
				<div className="col-full">
					<h1 className="intro-header" data-aos="fade-up">
						Téléchargez "Marches Points Verts" dès maintenant ! (version beta)
					</h1>

					<p className="lead" data-aos="fade-up">
						C'est simple, c'est gratuit, et c'est là pour vous rendre la vie plus active. Téléchargez notre application "Marches Points Verts" dès aujourd'hui !{" "}
					</p>

					<p>
						<b>
							<u>Note importante</u>
						</b>{" "}
						: Marches Points Verts est actuellement en phase de test. Certaines fonctionnalités (comme les notifications ou les rappels) ne sont pas encore disponibles et quelques bugs peuvent être présents. En téléchargeant 'Marches Points Verts' vous êtes conscient de cela et acceptez les dysfonctionnements éventuels. Vous pouvez nous contacter et nous faire part de vos remarques, problèmes, idées et propositions par email à{" "}
						<a style={{ color: "white" }} href="mailto:marchespointsverts@gmail.com">
							marchespointsverts@gmail.com
						</a>{" "}
						ou via la fonctionnalité "Nous contactez" dans la section "Aide" de l'application.
					</p>
					<ul className="download-badges">
						<li>
							<a href="https://apps.apple.com/app/id6470001814" title="" rel="noreferrer" target="_blank" className="badge-appstore" data-aos="fade-up">
								App Store
							</a>
						</li>
						<li>
							<a href="https://play.google.com/store/apps/details?id=com.pointsverts" title="" rel="noreferrer" target="_blank" className="badge-googleplay" data-aos="fade-up">
								Play Store
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Download;
