import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
	return (
		<footer>
			<div className="footer-main">
				<div className="row">
					<div className="col-three md-1-3 tab-full footer-info">
						{/*<div className="footer-logo"></div>*/}
					</div>
				</div>
			</div>

			<div className="footer-bottom">
				<div className="row">
					<div className="col-twelve">
						<div className="copyright">
							<span>© Copyright Frédéric Ponchaut 2023.</span>
						</div>

						<div id="go-top">
							<AnchorLink className="smoothscroll" title="Back to Top" href="#top">
								<i className="icon-arrow-up"></i>
							</AnchorLink>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
