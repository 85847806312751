const About = () => {
	return (
		<section id="about">
			<div className="row about-intro">
				<div className="col-four">
					<h1 className="intro-header" data-aos="fade-up">
						Fonctionnalités
					</h1>
				</div>
				<div className="col-eight">
					<p className="lead" data-aos="fade-up">
						"Marches Points Verts" facilite la découverte des "Points Verts" proches de chez vous, en mettant à votre disposition une gamme de fonctionnalités pratiques, notamment :{" "}
					</p>
				</div>
			</div>

			<div className="row about-features">
				<div className="features-list block-1-3 block-m-1-2 block-mob-full group">
					<div className="bgrid feature" data-aos="fade-up">
						<span className="icon">
							<ion-icon name="calendar-outline" style={{ color: "#39b54a", fontSize: 50 }}></ion-icon>
						</span>

						<div className="service-content">
							<h3>
								Les prochaines marches
								<br />
								en un coup d'oeil
							</h3>

							<p>
								Gardez un oeil sur les prochains Points Verts de l'ADEPS. Vous visualisez directement les Points Verts de la semaine, mais aussi des prochaines semaines. Soyez tenu au courant des marches annulées et des derniers changements.
								<br />
								La recherche vous permettra de trouver votre balade idéale.
							</p>
						</div>
					</div>

					<div className="bgrid feature" data-aos="fade-up">
						<span className="icon">
							<ion-icon name="reader-outline" style={{ color: "#39b54a", fontSize: 50 }}></ion-icon>
						</span>

						<div className="service-content">
							<h3>
								Une présentation claire
								<br />
								et intuitive
							</h3>

							<p>Chaque marche sera présentée et illustrée de manière simple, claire et complète. Poussette, ravitaillement, accessibilité PMR, balade vélo/VTT, parcours santé. Ne ratez rien, choisissez vos prochaines balades en fonction de vos envies. </p>
						</div>
					</div>

					<div className="bgrid feature" data-aos="fade-up">
						<span className="icon">
							<ion-icon name="navigate-circle-outline" style={{ color: "#39b54a", fontSize: 50 }}></ion-icon>
						</span>

						<div className="service-content">
							<h3>
								Une localisation précise
								<br />
								&nbsp;
							</h3>

							<p>Les cartes vous permettent de trouver facilement le point de rendez-vous (secrétariat) pour le départ du Point Verts. Vous pourrez directement envoyer cette localisation vers l'application de guidage GPS de votre téléphone. Envie de découvrir d'autres marches, la carte principale vous affichera toutes les marches disponibles pour la date choisie.</p>
						</div>
					</div>

					<div className="bgrid feature" data-aos="fade-up">
						<span className="icon">
							<ion-icon name="notifications-outline" style={{ color: "#39b54a", fontSize: 50 }}></ion-icon>
						</span>

						<div className="service-content">
							<h3>
								Des notifications
								<br />
								pour ne rien rater
							</h3>

							<p>
								Pour ne plus rater une marche proche de chez vous, vous pouvez activer les rappels et les notifications.
								<br />
								(cette fonctionnalité sera disponible très prochainement)
							</p>
						</div>
					</div>

					<div className="bgrid feature" data-aos="fade-up">
						<span className="icon">
							<ion-icon name="finger-print-outline" style={{ color: "#39b54a", fontSize: 50 }}></ion-icon>
						</span>

						<div className="service-content">
							<h3>
								Respectueuse de votre <br />
								vie privée
							</h3>

							<p>
								La protection de votre vie privée est notre priorité. "Marches Points Verts" ne collecte aucune donnée personnelle et ne le fera jamais.
								<br />
								Les données utilisées sont entièrement anonymisées et restent sur votre téléphone.
							</p>
						</div>
					</div>

					<div className="bgrid feature" data-aos="fade-up">
						<span className="icon">
							<ion-icon name="gift-outline" style={{ color: "#39b54a", fontSize: 50 }}></ion-icon>
						</span>

						<div className="service-content">
							<h3>
								Totalement gratuite
								<br />
								&nbsp;
							</h3>

							<p>
								Notre application est entièrement gratuite.
								<br />
								Les quelques publicités (que nous garderons les plus discrètes possibles) nous permettent de garder Marche Points Verts gratuite et sans frais.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="row about-bottom-image">
				<img
					src="assets/images/app-screens-1200.png"
					srcSet="assets/images/app-screens-600.png 600w, 
					assets/images/app-screens-1200.png 1200w, 
					assets/images/app-screens-2800.png 2800w"
					sizes="(max-width: 2800px) 100vw, 2800px"
					alt="App Screenshots"
					data-aos="fade-up"
				/>
			</div>
		</section>
	);
};

export default About;
